@import "sass-lib";

@media (min-width: 0px) and (max-width: 1700px) {
	header{
		padding: 0 60px;
	}
}


@media (min-width: 0px) and (max-width: 1400px) {
	header{
		.logo{
			img{width: 170px;}
		}
		.nav-head{
			> li{
				margin-left: 15px;margin-right: 15px;
				a{
					&:before{left:-12px;}
				}
			}
		}
	}
}